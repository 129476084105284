@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon/icomoon/icomoon.eot?eui6ws');
  src:  url('../fonts/icomoon/icomoon.eot?eui6ws#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.woff2?eui6ws') format('woff2'),
    url('../fonts/icomoon/icomoon.ttf?eui6ws') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?eui6ws') format('woff'),
    url('../fonts/icomoon/icomoon.svg?eui6ws#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e917";
}
.icon-login:before {
  content: "\e918";
}
.icon-moving-in:before {
  content: "\e910";
}
.icon-moving-out:before {
  content: "\e911";
}
.icon-policies:before {
  content: "\e912";
}
.icon-bill:before {
  content: "\e913";
}
.icon-energy-on:before {
  content: "\e914";
}
.icon-enquiry:before {
  content: "\e915";
}
.icon-help:before {
  content: "\e916";
}
.icon-consulting:before {
  content: "\e90e";
}
.icon-cost:before {
  content: "\e90f";
}
.icon-download:before {
  content: "\e90d";
}
.icon-battery:before {
  content: "\e90c";
}
.icon-solar:before {
  content: "\e909";
}
.icon-electric-vehicle:before {
  content: "\e90a";
}
.icon-generation:before {
  content: "\e90b";
}
.icon-hot-water:before {
  content: "\e906";
}
.icon-air-conditioning:before {
  content: "\e902";
}
.icon-cold-water:before {
  content: "\e903";
}
.icon-electricity:before {
  content: "\e904";
}
.icon-gas:before {
  content: "\e905";
}
.icon-plus:before {
  content: "\e907";
}
.icon-telecommunications:before {
  content: "\e908";
}
.icon-caret-bold:before {
  content: "\e900";
}
.icon-close:before {
  content: "\e901";
}
